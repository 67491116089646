import { CalendarItem } from '@/features/calendars/types'
import { usePutAchievementMutation, usePutPlannerItemMutation } from '@/redux/features/api'
import { NewItem, Item, PartialK } from '@/types'
import { AchievementId } from '@/types/game'
import { generateItemId } from '@/utils/item'
import { useCallback } from 'react'

export const usePutPlannerItem = () => {
    const [put] = usePutPlannerItemMutation()
    const [putAchievement] = usePutAchievementMutation()

    const putItem = useCallback(
        (item: NewItem | PartialK<Item, 'category'> | CalendarItem, options?: { achievement?: AchievementId, callback?: (id: string | undefined)=>void }) => {
            let isNewItem = false
            const newItem = { ...item, ...(!item.type && !item.id && ('dateEnd' in item && item.dateEnd ? { type: 'event' } : { type: 'task' })) }
            if (!newItem.id && item.type) {
                newItem.id = generateItemId(item.type, 'childType' in item ? item.childType : undefined)
                isNewItem = true
            }

            const res = put({ item: newItem as NewItem, isNew: isNewItem || !item.id })
            res.then(() => {
                if (options?.achievement) {
                    putAchievement({ achievementId: options.achievement, proof: newItem.id })
                }
                options?.callback?.(newItem.id)
            })
            return newItem.id
        },
        [put, putAchievement]
    )

    return { putItem }
}
