'use client'
import { FeatureEnum } from '@/features/game/levels/config/features'
import { useAppSelector } from '@/redux/hooks'
import { ReactNode } from 'react'

const FeatureAccess = ({ children, feature }: { children: ReactNode, feature: FeatureEnum }) => {
    const isEnabled = useAppSelector((state) => state.featureAccess.featureAccessMap[feature])
    if (!isEnabled) return null
    return children
}

export default FeatureAccess
