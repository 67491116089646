import { openItemForm, setOpenItemForm } from '@/redux/features/temporaryStateSlice'
import { useAppDispatch } from '@/redux/hooks'
import { PartialAllItemProperties } from '@/types'
import { useCallback } from 'react'

const useGlobalForm = (defaultValues?: PartialAllItemProperties) => {
    const dispatch = useAppDispatch()
    // const openForm = useAppSelector((state) => state.temporaryState.openForm)
    // const defaultValues = useAppSelector((state) => state.temporaryState.defaultFormItems)
    const setOpenForm = useCallback((open: boolean) => dispatch(setOpenItemForm(open)), [dispatch])

    const openEditItemForm = useCallback(
        (item?: PartialAllItemProperties) => {
            dispatch(openItemForm({ ...defaultValues, ...item }))
        },
        [dispatch, defaultValues]
    )

    return {
        setOpenForm,
        openEditItemForm,
    }
}

export default useGlobalForm
