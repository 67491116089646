'use client'
import React, { ReactNode, useId } from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { styled } from 'styled-system/jsx'
import { CheckIcon } from '@radix-ui/react-icons';
import InputLabel from './InputLabel';
import { RecipeVariantProps } from 'styled-system/types';
import { cva } from 'styled-system/css';
import { categoryColorDynamic } from '@/utils/categories';
import { Modify } from '@/types';
// ex. checked={displayOptions.hideCompleted}
// onCheckedChange={(checked) => editOptions('hideCompleted', !!checked)}

const checkbox = cva({
    base: {
        all: 'unset',
        boxSizing: 'border-box',
        backgroundColor: '$loContrast',
        size: '1.25rem',
        borderRadius: '0.1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
        marginRight: '0.5rem',
        '&:hover': { boxShadow: `0 0 0 0.5px` },
        '&:focus': { boxShadow: `0 0 0 1px` },
    },
    variants: {
        icon: {
            circle: { // TODO: FIRST LOAD SOMETIMES NOT LOADING
                backgroundColor: 'transparent',
                borderRadius: '50%',
                border: '$borderThin',
                boxShadow: 'none',
                size: '1rem',
                maxHeight: '1rem',
                maxWidth: '1rem',

                minHeight: 16,
                minWidth: 16,
            },
            clear: {
                backgroundColor: 'inherit',
                boxShadow: 'none',
                borderRadius: '0',
                border: '$borderThin',
                width: '0.7rem',
                height: '0.7rem',
            }
        },
        fillOnChecked: {
            true: {
                '&[data-state="checked"]': {
                    color: '$text',
                    backgroundColor: '$text'
                },
            }
        },
        size: {
            small: {
                size: '0.95rem',
            }
        }
    }
})

export const CheckboxRoot = styled(CheckboxPrimitive.Root, checkbox);

const checkboxIndicator = cva({
    variants: {
        fillOnChecked: {
            true: {
                '&[data-state="checked"]': {
                    color: '$outline',
                    backgroundColor: "$outline",
                }
            }
        }
    },
    base: {
        color: "$primary11"
    }
})

export type CheckboxProps = RecipeVariantProps<typeof checkbox> & RecipeVariantProps<typeof checkboxIndicator> & Modify<React.ComponentProps<typeof CheckboxPrimitive.Root>, {
    checked?: boolean, onCheckedChange: (checked: boolean) => void, id?: string, children?: ReactNode,
    style?: any,
    defaultChecked?: boolean,
    colorNum?: number | string,
    labelStyles?: any,
    ref?: any,
}>

const Checkbox = ({ defaultChecked, checked, onCheckedChange, id, style = {}, labelStyles, children, colorNum, size, ...props }:
    CheckboxProps) => {
    const defaultId = useId()
    return (
        <Fieldset>
            <CheckboxRoot
                {...props}
                size={size}
                style={{ ...style,
                    ...(colorNum !== undefined && categoryColorDynamic(colorNum)) }}
                checked={checked}
                defaultChecked={defaultChecked}
                onCheckedChange={onCheckedChange}
                id={id || defaultId}>
                <CheckboxIndicator style={{ ...(colorNum !== undefined && categoryColorDynamic(colorNum)) }} >
                    <Checkmark isCircle={props.icon === 'circle'} size={size} />
                </CheckboxIndicator>
            </CheckboxRoot>
            {children && <InputLabel style={labelStyles} htmlFor={id || defaultId}>{children}</InputLabel>}
        </Fieldset>
    )
}

export default Checkbox

export const Checkmark = styled(CheckIcon, {
    variants: {
        size: {
            small: {
                size: '14px'
            },
        },
        isCircle: {
            true: {
                height: 55,
                width: 35
            }
        }
    },
})

export const Fieldset = styled('fieldset', {
    base: {
        all: 'unset',
        display: 'flex',
        gap: 2,
        marginBlock: '3px',
        alignItems: "center"
    }
});

// export const CheckboxRootCircle = styled(CheckboxPrimitive.Root, {
//     base: {
//         all: 'unset',
//         boxSizing: 'border-box',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         marginRight: '0.5rem',
//         '&:hover': { boxShadow: `0 0 0 0.5px` },
//         '&:focus': { boxShadow: `0 0 0 1px` },

//         backgroundColor: 'transparent',
//         borderRadius: '50%',
//         border: '$borderThin',
//         boxShadow: 'none',
//         size: '1rem',
//         maxHeight: '1rem',
//         maxWidth: '1rem',
//         [`&${Checkmark}`]: {
//             height: 55,
//             width: 35,
//         },
//         [`&${InputLabel}`]: {
//             fontSize: '$3'
//         },
//         minHeight: 16,
//         minWidth: 16,
//     }
// });

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, checkboxIndicator);