'use client'
import { useGetUserQuery } from '@/redux/features/api'
import React from 'react'
import Image from 'next/image'
import { css } from 'styled-system/css'

const HeaderPanda = () => {
    const { data: user } = useGetUserQuery()

    if (user?.style === 'plain') return null
    return (
        <div className={css({
            position: 'relative',
            display: 'inline-block',
            height: '100%',
            aspectRatio: '400/300',
        })}>
            <Image src={'/planda_header_icon.png'} alt="panda-image" className={css({ objectFit: 'contain' })} fill />
        </div>
    )
}

export default HeaderPanda
