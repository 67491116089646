'use client'
import React, { useCallback } from 'react'
import { styled } from 'styled-system/jsx'
import FluidTasksDisplay from './FluidTypeTasks'
import { useGetTinyTasksQuery, usePutTinyTasksMutation } from '@/redux/features/api'
import PawPrints from '../common/loaders/PawPrints'
export const IconBar = styled('span', {
    base: {
        display: 'flex',
        marginRight: '1rem',
        right: 0,
        position: 'absolute',
    },
})

const TinyTasksDisplay = ({}: {}) => {
    // every newline is a new item
    // use a textarea to edit
    const { data, isLoading } = useGetTinyTasksQuery()
    const [put] = usePutTinyTasksMutation()

    const { storage } = data || {}

    const edit = useCallback((storage: string) => {
        put({ storage })
    }, [put])

    if (isLoading) return <PawPrints />
    return (
        <FluidTasksDisplay name='tiny tasks' storage={storage || ''} edit={edit} placeholder="Type your tiny tasks in here." size="sm" showProgress />
        //     {list.length === 0 && !isEditing ? <Placeholder>
        //         Click the pencil to tiny tasks&nbsp;<EditIcon ref={outsideAlertOmitRef}
        //             onClick={() => setIsEditing(p => !p)}
        //         />
        //     </Placeholder> : <IconBar>
        //         <EditIcon ref={outsideAlertOmitRef}
        //             onClick={() => setIsEditing(p => !p)}
        //         />
        //     </IconBar>}
        //     {
        //         isEditing ?
        //             <TextAreaHolder>
        //                 <TextArea placeholder='Type your tiny tasks in here.' ref={outsideAlertRef} defaultValue={storage}
        //                     onChange={(e) => debouncedEdit(e.target.value)}
        //                 />
        //                 <GoodIcon />
        //             </TextAreaHolder>
        //             : <CheckList
        //                 className={css({ maxHeight: 340, minHeight: 0, height: '100%', overflow: 'auto', paddingBottom: 60 })}
        //                 items={list.map(({ text, checked }) => ({ html: <div>{text}</div>, checked }))}
        //                 handleChecked={async (index, checked) => {
        //                     // const newList = list.map((item, i) => i === index ? { ...item, checked } : item)
        //                     setChecked(index, checked)
        //                 }}
        //             />
        //     }
        //     <End>
        //         {progress !== null && list.length >= 3 && <ProgressBar progress={progress * 100} />}
        //     </End>
        // </Container>
    )
}

export default TinyTasksDisplay
