import { CalendarEvent, CalendarItem, DayOfWeek } from './types'
import {
    addDays,
    addMonths,
    addWeeks,
    differenceInCalendarDays,
    differenceInMilliseconds,
    endOfDay,
    endOfMonth,
    endOfWeek,
    getWeeksInMonth,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    isSameWeek,
    isWithinInterval,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns'
import { groupBy, uniq } from 'lodash'
import { mod } from 'src/utils/math'
import { changeDay, isAlmostFullDay } from '@planda/utils'
import EventCronParser from 'event-cron-parser'
import { nanoid } from 'nanoid'
import { MS_PER_MINUTE } from './constants'

export function getDaygridLayoutInfo(layoutUnit: 'day' | 'week' | 'month', layoutVal: number, activeDay: Date | number, weekStartsOn: DayOfWeek) {
    if (layoutUnit !== 'day' && layoutUnit !== 'week' && layoutUnit !== 'month') throw new Error('invalid layout type')
    const days = layoutUnit === 'day' ? layoutVal : layoutUnit === 'week' ? layoutVal * 7 : getWeeksInMonth(activeDay, { weekStartsOn }) * 7 * layoutVal
    const start =
        layoutUnit === 'day' && layoutVal < 7 ? startOfDay(activeDay) : layoutUnit === 'week' ? startOfWeek(activeDay, { weekStartsOn }) : startOfWeek(startOfMonth(activeDay), { weekStartsOn })
    const end = endOfDay(addDays(start, days - 1))
    return {
        days,
        start,
        end,
    }
}

export function moveDateOrIntervalDay(dates: { dateStart: Date | number; dateEnd?: Date | number }, targetDay: Date | number) {
    if (!dates.dateEnd) return { dateStart: changeDay(dates.dateStart, targetDay).getTime() }
    const duration = differenceInMilliseconds(dates.dateEnd, dates.dateStart)
    const dateStart = changeDay(dates.dateStart, targetDay).getTime()
    return {
        dateStart,
        dateEnd: dateStart + duration,
    }
}

// export function convertColorToCSS(color?: Color) {
//     return (color && { backgroundColor: color.bg, color: color.txt })
// }

export function itemIsWithinInterval(item: CalendarItem, start: Date | number, end: Date | number) {
    if (!item.dateEnd) {
        return isWithinInterval(item.dateStart, { start, end })
    }
    return isBefore(item.dateStart, end) && isAfter(item.dateEnd, start)
}

export function createDayMap(events: CalendarEvent[]) {
    if (!events) return {}
    const grouped = groupBy(events, (event) => startOfDay(event.dateStart).getTime())
    return grouped
}

export function earliestDate(date1: Date | number, date2: Date | number) {
    return isBefore(date1, date2) ? date1 : date2
}

export function dateHelpersByType(type: 'day' | 'week' | 'month', weekStartsOn: DayOfWeek = 1) {
    if (type !== 'day' && type !== 'week' && type !== 'month') throw new Error('invalid type')
    return {
        startOf: type === 'day' ? startOfDay : type === 'week' ? (d1: Date | number) => startOfWeek(d1, { weekStartsOn }) : startOfMonth,
        endOf: type === 'day' ? endOfDay : type === 'week' ? (d1: Date | number) => endOfWeek(d1, { weekStartsOn }) : endOfMonth,
        isSame: type === 'day' ? isSameDay : type === 'week' ? (d1: Date | number, d2: Date | number) => isSameWeek(d1, d2, { weekStartsOn }) : isSameMonth,
        add: type === 'day' ? addDays : type === 'week' ? addWeeks : addMonths,
    }
}

// 1: 0->7, 1->1
// 0: 0->1, 1->2...6->7
// 2: 0->6, 1->7, 2->1, 3->2...6->5
// position is 1-indexed
export function getPositionOfDayOfWeek(dayOfWeek: number, startingDayOfWeek = 1) {
    return mod(dayOfWeek - startingDayOfWeek + 1, 7) || 7
}

export function numberOfDaysSpanned(start: Date | number, end: Date | number) {
    // minimum return value = 1
    // crop off last few minutes of end date
    return Math.abs(differenceInCalendarDays(start, new Date(end).getTime() - MS_PER_MINUTE)) + 1
    // if (new Date(start).getTime() > new Date(end).getTime()) throw new Error('start date must be earlier than end date')
    // if (isSameDay(start, end)) return 1
    // if (isSameMonth(start, end)) return new Date(end).getDate() - new Date(start).getDate() + 1
    // return daysBetween(start, end) // !!! not best method, can be inaccurate
}

export function handleCronDayOfWeekChange(cronParser: EventCronParser, prevDayOfWeek: number, newDayOfWeek: number) {
    const localDaysOfWeek = uniq(
        cronParser.getLocalDays().map((x) => {
            if (x === prevDayOfWeek) return newDayOfWeek
            return x
        })
    )
    cronParser.setDaysOfWeek(localDaysOfWeek, 'local')
}

export function splitEventByDateUnit(splitEventsBy: 'day' | 'week' | 'month', event: CalendarEvent) {
    const { startOf, endOf, add, isSame } = dateHelpersByType(splitEventsBy)
    const items: CalendarEvent[] = []
    let start = new Date(event.dateStart)
    while (isBefore(start, event.dateEnd) || isSame(start, event.dateEnd)) {
        const id = event.id + nanoid(6)
        const newItem = { ...event, dateStart: start.getTime(), dateEnd: earliestDate(endOf(start).getTime(), event.dateEnd) as number, id }
        items.push(newItem)
        start = startOf(add(start, 1))
    }
    return [...items]
}

export const isAllDay = (e: CalendarEvent) => isAlmostFullDay(e.dateStart, e.dateEnd)
