'use client'
import React, { forwardRef } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { TutorialArrow as TutorialArrowType } from '@/redux/features/temporaryStateSlice'
import { Arrow } from '../common'
import { SystemStyleObject } from 'styled-system/types'
import { css } from 'styled-system/css'

const TutorialArrow = ({
    cssRaw,
    tutorialArrow,
    direction = 'left',
}: {
    cssRaw?: SystemStyleObject
    tutorialArrow: TutorialArrowType
    direction?: 'left' | 'right'
}) => {
    const isActive = useAppSelector((state) => state.temporaryState.tutorialArrow)
    if (tutorialArrow !== isActive) return null
    return <Arrow cssRaw={cssRaw} direction={direction} />
}

export default TutorialArrow

export const ExactTutorialArrow = forwardRef<
    HTMLElement,
    {
        cssRaw?: SystemStyleObject
        tutorialArrow: TutorialArrowType
        direction?: 'left' | 'right'
    }
>(({ cssRaw, tutorialArrow, direction = 'left' }, ref) => {
    const isActive = useAppSelector((state) => state.temporaryState.tutorialArrow)
    if (tutorialArrow !== isActive) return null
    const containerRef = ref as React.MutableRefObject<HTMLElement> | null
    if (!containerRef?.current) return null
    let { top, left, height, width } = containerRef.current.getBoundingClientRect()
    const style =
        direction === 'left' ? { top: top - 30 + height / 2, left: left + width * 0.8 } : { top: top - 30 + height / 2, left: left - width / 2 }
    return <Arrow cssRaw={css.raw({ position: 'fixed', transform: 'none', transformOrigin: 'center' }, cssRaw)} direction={direction} style={style} />
})

ExactTutorialArrow.displayName = 'ExactTutorialArrow'
