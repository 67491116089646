import * as SliderPrimitive from '@radix-ui/react-slider';
import { styled } from 'styled-system/jsx'
import { css } from 'styled-system/css';
import Image from 'next/legacy/image';
import * as ProgressPrimitive from '@radix-ui/react-progress';

const SlidersWrapper = styled('section', {
    base: {
        position: "relative",
        width: 200,
        minWidth: 0,
    }
})

const Flex = styled('div', {
    base: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        marginRight: "25%"
    }
})

const ItemProgress = ({ progress, width, height, showPanda, label = "Progress" }: { progress: number, width?: string | number, showPanda?: boolean, label?: string | null, height?: string | number }) => {

    return (
        <SlidersWrapper style={{ ...(width !== undefined && { width }), }} >
            <Flex>
                {label && <label>{label}</label>}
                {/* Status */}
                {showPanda && <Image
                    className={css({ top: 5, right: '0' })}
                    width={70} height={40}
                    src={'/tinyPanda.png'}
                    alt='tiny panda sitting on a progress bar'
                />}
            </Flex>

            <Progress value={progress} style={{ ...(height !== undefined && { height }) }}>
                <ProgressIndicator style={{ transform: `translateX(-${100 - progress}%)` }} />
            </Progress>

            {/* <GoalSlider defaultValue={[0]} step={5} >
                <GoalTrack>
                    <GoalRange />
                </GoalTrack>
                <GoalThumb asChild>
                    <ThumbWrapper>
                        <svg className={css({ position: 'absolute' })} width="15" height="15" viewBox="4 4.5 7 4.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path></svg>
                        <GoalText>Today&apos;s Goal</GoalText>
                    </ThumbWrapper>
                </GoalThumb>
            </GoalSlider> */}
        </SlidersWrapper>
    );
}

export default ItemProgress

// replace progress with below to make it a slider
{/* <Slider defaultValue={[50]} max={100} step={1} aria-label="Volume"
                value={value}
                onValueChange={(value) => {
                    setValue(value)
                    updateDB(value[0])
                }}
                className={css({ width: '100%' })}
            >
                <SliderTrack>
                    <SliderRange />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}

const HEIGHT = '2rem'

const StyledProgress = styled(ProgressPrimitive.Root, { base: {
    position: 'relative',
    overflow: 'hidden',
    background: '$overlay6',

    width: '100%',
    height: HEIGHT,

    backgroundColor: '$gray1',
    borderRadius: 5,
    border: '$borderThin',

    transform: 'translateZ(0)',
} });

const StyledIndicator = styled(ProgressPrimitive.Indicator, { base: {
    width: '100%',
    height: '100%',
    transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',

    backgroundColor: '$primary4', // TODO: what number?
} });

// Exports
export const Progress = StyledProgress;
export const ProgressIndicator = StyledIndicator;





// !!! not in use
const goalSliderGrabHeight = 0
const GoalSlider = styled(SliderPrimitive.Root, { base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    zIndex: 1,

    '&[data-orientation="horizontal"]': {
        height: goalSliderGrabHeight,
    },

    '&[data-orientation="vertical"]': {
        flexDirection: 'column',
        width: goalSliderGrabHeight,
        height: 100,
    },
} });

const GoalTrack = styled(SliderPrimitive.Track, {
    base: {
        backgroundColor: "$overlay7",
        position: "relative",
        flexGrow: 1,
        borderRadius: "9999px",
        "&[data-orientation=\"horizontal\"]": {
            height: 0
        },
        "&[data-orientation=\"vertical\"]": {
            width: 0
        }
    }
});

const GoalRange = styled(SliderPrimitive.Range, {
    base: {
        position: "absolute",
        backgroundColor: "$loContrast",
        borderRadius: "9999px",
        height: "100%"
    }
});

const ThumbWrapper = styled('div', {
    base: {
        color: "red"
    }
})

const GoalThumb = styled(SliderPrimitive.Thumb, {
    base: {
        all: "unset",
        display: "block",
        color: "red",
        flexGeneral: "column"
    }
});

const GoalText = styled('label', {
    base: {
        position: "absolute",
        fontSize: "$1",
        center: "row",
        whiteSpace: "nowrap",
        top: "1rem"
    }
})