export const MIN_LINKS = 1
export const EMPTY_LINK = { url: '', text: '' } as const
export const MIN_SUBTASKS = 2
export const EMTPY_SUBTASK = { name: '', completed: 0 } as const
export const EMTPY_PERSON = { name: '', email: '', description: '', contactType: '' } as const
export const FORM_GAP = 20

export const EMPTY_PERSON = { name: '', } as const

export const DEFAULT_CRON = "0 0 ? *  *"
export const DEFAULT_RATE = 'rate(0 days)'

export const FORM_ITEM_DEFAULTS = {
    priority: 1,
    category: '/',
    name: '',
    notes: '',
    links: [{ ...EMPTY_LINK }],
    subtasks: [{ ...EMTPY_SUBTASK }, { ...EMTPY_SUBTASK }],
    // date: null as null | Date,
    date: [null, null] as null[],
    time: [null, null] as null[],
    completed: 0,
    durationValue: 1,
    durationUnit: 'hour',
    isMultiDay: false,
    isWorkDay: false,
    formPath: 'task' as const,
    cronInfo: {
        formatCron: 1 as 0 | 1,
        rateIntervalDays: 7 as number | undefined,
        daysOfWeek: [new Date().getDay().toString()] as string[] | undefined, // TODO: very hacky, should eventually fix
    }
}