import React, { ReactNode } from 'react'
import Checkbox, { CheckboxProps } from './Checkbox'
import { styled } from 'styled-system/jsx'
import { PartialK } from '@/types'

function CheckList({ items, handleChecked, style, className }: {
    handleChecked?: (i: number, checked: boolean) => void,
    items: ({ html: ReactNode, checked: boolean } & PartialK<CheckboxProps, 'onCheckedChange'>)[],
    style?: any,
    className?: string
}) {
    return (
        <Ul style={style} className={className}>
            {items.map(({ html, checked, ...props }, index) => <li
                key={index + checked.toString()}
                style={checked ? { textDecoration: 'line-through', opacity: 0.7, color: "gray" } : {}}
            >
                <Checkbox
                    onCheckedChange={async (checked: boolean) => {
                        handleChecked?.(index, !!checked)
                    }}
                    defaultChecked={!!checked} icon={'clear'}
                    {...props}
                >
                    {html}
                </Checkbox>
            </li>)}
        </Ul>
    )
}

export default CheckList

const Ul = styled('ul', {
    base: {
        boxSizing: "border-box",
        listStyle: "none",
        margin: 0,
        padding: 0
    }
})