import { getCategoryNumGlobal, pathToCategoriesGlobal, pathToNamesArrayGlobal } from '@/utils/categories'
import useActiveQuery from './main/category/useActiveQuery'
import { Category, Section, TodoGroup } from '@/types'

const useCategoryNum = () => {
    const { categoryUnits, isLoading } = useActiveQuery()

    function pathToCategories(path: string | string[]): [Section] | [Section, Category] | [Section, Category, TodoGroup] | undefined {
        return pathToCategoriesGlobal(path, categoryUnits)
    }

    const pathToNamesArray = (path: string | string[]) => {
        return pathToNamesArrayGlobal(path, categoryUnits)
    }

    const getCategoryNum = (path: string | string[]): number | null => {
        return getCategoryNumGlobal(path, categoryUnits)
    }

    const getCategoryName = (path: string) => {
        const names = pathToNamesArray(path)
        return names?.at(1)
    }

    const isValidPath = (path: string | string[]) => {
        if (!categoryUnits) return
        try {
            pathToNamesArray(path)
            return true
        } catch (e) {
            return false
        }
    }

    const getCategoryUnit = (path: string | string[]) => {
        if (!categoryUnits) return
        if (typeof path === 'string') path = path.split('/')
        return pathToCategories(path)?.at(-1)
    }

    return {
        getCategoryNum,
        pathToNamesArray,
        getCategoryName,
        getCategoryUnit,
        pathToCategories,
        isValidPath,
        isLoading,
        categoryUnits,
    }
}

export default useCategoryNum
