'use client'
import useSWR0 from 'swr'
import { fetchGet, fetcher } from './fetch'
import { useAppSelector } from '@/redux/hooks'

export const useSWR = <T>(url: string | null | undefined, options?: { onSuccess?: (data: T) => void }) => {
    const shareLinkUrlInfo = useAppSelector((state) => state.viewMode.shareLinkUrlInfo)
    const props = useSWR0(
        url && [url, shareLinkUrlInfo?.sharingId],
        ([url, _]: [string, unknown]) =>
            fetchGet<T>(
                url,
                shareLinkUrlInfo || {},
                shareLinkUrlInfo
                    ? {
                          headers: {
                              'Sharing-By': shareLinkUrlInfo.sharingBy,
                              'Sharing-Id': shareLinkUrlInfo.sharingId,
                          },
                      }
                    : undefined
            ),
        options
    )

    return props
}

export const useSWRMethod = <T>(url: string, method: string, options?: { onSuccess?: (data: T) => void; body?: Record<string, any> }) => {
    const shareLinkUrlInfo = useAppSelector((state) => state.viewMode.shareLinkUrlInfo)
    const props = useSWR0([url, shareLinkUrlInfo?.sharingId], ([url, _]) => fetcher<T>(url, method, options?.body, shareLinkUrlInfo || {}), options)

    return props
}
