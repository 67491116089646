'use client'
import { MS_PER_MINUTE } from '@constants/date'
import { usePathname } from 'next/navigation'
import { useContext, createContext, useState, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { fetchDeleteItem, fetchPutItem } from 'src/lib/fetch'
import { NewItem, PartialK, PutItemSchema } from 'src/types'


interface UserAction {
    actionType: string,
    timestamp: number,
    page?: string,
    location: string, // parent component (ex. form)
    body: any,
}
// interface Action { item: Item, date: number, action: 'delete' }
export interface UserActionLogProps {
    userActions: UserAction[],
    setUserActions: ((item: UserAction[] | ((item: UserAction[]) => UserAction[])) => void),
    addAction: (action: PartialK<UserAction, 'timestamp'>) => void,
    getLastAction: (actionType?: string) => UserAction | undefined,
    // shareSettings?: { // moved to demo
    //     shareBy: string,
    //     share: string, // handle or link
    // }
}

export const UserActionLogContext = createContext<UserActionLogProps>({
    userActions: [], setUserActions: () => { },
    addAction: () => { }, getLastAction: () => undefined,
})

// export const ColorModeProvider = ({ children }: { children: ReactNode }) => {
//     const termProps = useTerm()

//     return <TermContext.Provider value={termProps} >
//         <div></div>
//     </TermContext.Provider>

// }

export function useGlobalUserActionLog() {
    return useContext(UserActionLogContext)
}

export function useUserActionLog() {
    const [userActions, setUserActions] = useState<UserAction[]>([])
    const [lastActionIndex, setLastActionIndex] = useState(-1)
    const pathname = usePathname()

    const addAction = useCallback((_action: PartialK<UserAction, 'timestamp'>) => {
        const action: UserAction = { ..._action, page: _action.page ?? pathname, timestamp: _action.timestamp ?? Date.now() }
        setUserActions(actions => [...actions.slice(0, actions.length + lastActionIndex + 1), action])
        setLastActionIndex(-1)
    }, [pathname, lastActionIndex])

    const getLastAction = () => {
        return userActions.at(lastActionIndex)
    }

    async function undoLastAction() {
        const lastAction = getLastAction()
        if (!lastAction) return
        if (lastAction.timestamp < Date.now() - MS_PER_MINUTE) return // don't allow undoing actions older than 1 minute
        if (lastAction.actionType === 'item.delete') {
            const newItem = PutItemSchema.parse(lastAction.body) as NewItem
            // put(newItem)
            // console.log("undoing delete of ", newItem)
            fetchPutItem(newItem)
        }
        setLastActionIndex(index => index - 1)
        // setUserActions(actions => actions.slice(0, -1))
    }

    function undoLastUndo() {
        if (userActions.length === 0) return
        const lastAction = userActions.at(lastActionIndex + 1)
        if (!lastAction) return
        if (lastAction.actionType === 'item.delete') {
            fetchDeleteItem(lastAction.body.id)
            setLastActionIndex(index => index + 1)
        }
    }

    useHotkeys('mod+z', () => undoLastAction(), [userActions])
    useHotkeys('shift+mod+z', () => undoLastUndo(), [userActions])

    const props: UserActionLogProps = {
        userActions: userActions,
        setUserActions: setUserActions,
        addAction: addAction,
        getLastAction: getLastAction,
    }

    return props
}