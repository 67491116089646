import { useGlobalUserActionLog } from '@/context/useUserActionLog'
import { useDeletePlannerItemMutation, useUpdatePlannerItemMutation } from '@/redux/features/api'
import { getChildTypeFromId, getTypeFromId } from '@/utils/item'
import { getDayStringFormat } from '@planda/utils'
import { useCallback } from 'react'
import Swal from 'sweetalert2'
import useFindItem from './useFindItem'

const useRemoveItem = () => {
    const [updateItem] = useUpdatePlannerItemMutation()
    const [deleteItem] = useDeletePlannerItemMutation()
    const { addAction } = useGlobalUserActionLog()
    const { find } = useFindItem()

    const exclude = useCallback(
        async (id: string, date: number) => {
            if (getTypeFromId(id) !== 'templateRecur' || getChildTypeFromId(id) !== 'event') return
            const val = getDayStringFormat(date)
            return updateItem({ id, updates: { add: { excludedDays: [val] } } })
        },
        [updateItem]
    )

    const remove = useCallback(
        async (id: string) => {
            const item = find(id)
            if (!item) {
                addAction({
                    actionType: 'item.nop',
                    location: 'item',
                    body: { id },
                })
                return deleteItem(id)
            }

            if (item.recurId) {
                const res = await Swal.fire({
                    title: `Deleting ${item.name}`,
                    text: `Delete all past and future events for ${item.name}?`,
                    icon: 'question',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete all',
                    denyButtonText: 'Just this one',
                })
                if (res.isDismissed) return
                if (res.isConfirmed) {
                    id = item.recurId
                } else if (res.isDenied && 'dateStart' in item && item.dateStart) {
                    return exclude(item.recurId, item.dateStart)
                }
            }

            item &&
                addAction({
                    actionType: 'item.delete',
                    location: 'item',
                    body: item,
                })

            return deleteItem(id)
        },
        [find, deleteItem, addAction, exclude]
    )

    return {
        remove,
        exclude,
    }
}

export default useRemoveItem
