'use client'
import FeatureAccess from '@/components/common/FeatureAccess'
import FinalsToGrade, { canUseFinalsToGrade } from '@/components/graphs/FinalsToGrade'
import GoalOneLineText from '@/components/specific/GoalOneLineText'
import useCategories from '@/hooks/useCategories'
import React from 'react'

const Right = () => {
    const { groups, categoryList } = useCategories()

    const conditionsMetForFinalsToGrade = canUseFinalsToGrade(groups, categoryList)

    if (conditionsMetForFinalsToGrade) {
        return <FinalsToGrade />
    }

    return (
        <FeatureAccess feature="goals">
            <GoalOneLineText />
        </FeatureAccess>
    )
}

export default Right
