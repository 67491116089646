import React from 'react'
import { css } from 'styled-system/css'
import { SystemStyleObject } from 'styled-system/types'

const PawPrints = ({ size, cssRaw }: { size?: 'xs' | 'sm' | 'md' | 'lg'; cssRaw?: SystemStyleObject }) => {
    return (
        <div
            className={css(
                {
                    scale: size === 'xs' ? 0.5 : size === 'sm' ? 0.75 : size === 'lg' ? 1.5 : 1,
                    width: 'calc(6*30px)',
                    height: '50px',
                    display: 'flex',
                    color: '#8d7958',
                    filter: 'drop-shadow(30px 25px 0 currentColor) drop-shadow(60px 0 0 currentColor) drop-shadow(120px 0 0 currentColor)',
                    clipPath: 'inset(0 100% 0 0)',
                    animation: 'footSteps',
                    _before: {
                        content: '""',
                        width: '30px',
                        height: '25px',
                        '--c': 'no-repeat radial-gradient(farthest-side,currentColor 92%,#0000)',
                        background:
                            'var(--c) left /70% 70%,\n    var(--c) right/20% 20%,\n    var(--c) top    0 right 15%/20% 20%,\n    var(--c) bottom 0 right 15%/20% 20%',
                    },
                },
                cssRaw
            )}
        />
    )
}

export default PawPrints
