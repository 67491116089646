import useGetCategoryRes from '@/hooks/useGetCategoryRes'
import { getTypeFromId } from '@/utils/item'
import { useCallback } from 'react'

const useFindItem = () => {
    const queryRes = useGetCategoryRes()

    const find = useCallback(
        (id: string) => {
            const type = getTypeFromId(id)
            return queryRes.data?.items[type].find((x) => x.id === id)
        },
        [queryRes.data]
    )

    return {
        ...queryRes,
        find,
    }
}

export default useFindItem
