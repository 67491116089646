const shrinkTextRef = (contentEl: HTMLElement | null, { horizontal = false, debug }: { horizontal?: boolean; debug?: string } = {}) => {
    const MIN_FONT_SIZE = 10

    if (!contentEl) return

    let fontSize = 100

    /** Returns true if the text overflows past the window height. */
    const overflow = () => {
        const parentHeight = contentEl.parentElement?.getBoundingClientRect().height
        if (parentHeight && contentEl.scrollHeight <= parentHeight) {
            return false
        }
        const hasOverflow = horizontal ? contentEl.scrollWidth > contentEl.clientWidth : contentEl.scrollHeight > contentEl.clientHeight
        if (debug)
            console.log('hasOverflow', debug, contentEl.scrollHeight, contentEl.clientHeight)
        return hasOverflow
    }

    /** Decreases the font size of the element. */
    const shrinkFontSize = (el: HTMLElement) => (el.style.fontSize = --fontSize + '%') // eslint-disable-line no-return-assign

    if (fontSize) {
        while (overflow() && fontSize >= MIN_FONT_SIZE) {
            shrinkFontSize(contentEl)
        }
    }
}

export default shrinkTextRef
