import React from 'react'
import ItemProgress from './ItemProgress'
import { styled } from 'styled-system/jsx'

//  SMALL (checklist) AND LARGE VERSIONS (course)
const ProgressBar = ({progress}: {progress: number}) => {
  return (
    <Container>
      <End>
        <Center><ItemProgress width={'90%'} progress={progress} /></Center>
      </End>
    </Container>
  )
}

export default ProgressBar

const Container = styled('div', {
    base: {
        position: "relative",
        bottom: 0,
        width: "100%"
    }
})

const Center = styled('div', {
    base: {
        center: "row",
        marginBottom: "10%"
    }
})

const End = styled('section', {
    base: {
        display: "flex",
        height: "stretch",
        flexDirection: "column",
        justifyContent: "end"
    }
})