import useGoals from '@/hooks/useGoals'
import { useGetSmartGoalsOfStretchGoalQuery } from '@/redux/features/api'
import { skipToken } from '@reduxjs/toolkit/query'
import { random } from 'lodash'
import React from 'react'
import { cva } from 'styled-system/css'

const viewName = cva({
    base: {
        gridArea: 'goal',
        fontSize: '$5',
        opacity: 0.5,
        fontWeight: 'bold',
        textAlign: 'end',
        // color: '$unimportant',
        display: 'flex',
        marginInline: '$6',
        justifyContent: 'flex-end',
        alignItems: 'center',
        transition: 'opacity 0.5s',
        _hover: {
            opacity: 1,
        },
    },
})

const GoalOneLineText = () => {
    const { topStretchGoal } = useGoals()
    const { data: smartGoals = [] } = useGetSmartGoalsOfStretchGoalQuery(topStretchGoal?.id || skipToken)

    const randomStretchGoal = smartGoals.length > 0 ? smartGoals[random(0, smartGoals.length - 1)] : undefined

    const options = [randomStretchGoal?.specific, topStretchGoal?.oneLiner || topStretchGoal?.goal].filter(Boolean)

    if (options.length === 0) return null

    const randomOption = options[random(0, options.length - 1)]

    return <div className={viewName()}>&#127919; {randomOption}</div>
}

export default GoalOneLineText
