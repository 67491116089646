import { z } from 'zod'

export const StretchGoalsOrderSchema = z.object({
    id: z.literal('GOAL_ORDER_STRETCH'),
    order: z.string().array(),
})

export const StretchGoalSchema = z.object({
    id: z.string(), // GOAL_STRETCH#<stretchId>
    goal: z.string(),
    description: z.string().optional(),
    oneLiner: z.string().optional(),
    progress: z.number(),
})

export const TimeBoundSchema = z.object({
    notes: z.string(),
    dateStart: z.number().nullable(),
    dateEnd: z.number().nullable(),
})

export const SmartGoalSchema = z.object({
    id: z.string(), // GOAL_SMART#<stretchId>/<smartId>
    specific: z.string(),
    measurable: z.string(),
    achievable: z.string(),
    relevant: z.string(),
    timeBound: TimeBoundSchema,
    progress: z.number(),
    stretchGoalId: z.string(),
})

export type StretchGoalsOrder = z.infer<typeof StretchGoalsOrderSchema>
export type StretchGoal = z.infer<typeof StretchGoalSchema>
export type SmartGoal = z.infer<typeof SmartGoalSchema>
export type SmartGoalTimeBound = z.infer<typeof TimeBoundSchema>