import { useGetStretchGoalsOrderQuery, useGetStretchGoalsQuery } from '@/redux/features/api'
import { StretchGoal, StretchGoalSchema } from '@/types/goal'
import { useForageItem } from '@planda/design-system'
import { uniq } from 'lodash'
import { useMemo } from 'react'

const useGoals = () => {
    const { item: localItem, set } = useForageItem<null | StretchGoal>('topStretchGoal', (value) => StretchGoalSchema.safeParse(value).success, null)
    // get goals from local storage
    const { data: stretchGoalsData, isLoading } = useGetStretchGoalsQuery()
    const { data: savedOrder } = useGetStretchGoalsOrderQuery()

    const { order, topStretchGoal, stretchGoals } = useMemo(() => {
        const stretchGoals = !stretchGoalsData && isLoading && localItem ? [localItem] : stretchGoalsData
        const goalIds = stretchGoals?.map((goal) => goal.id) || []
        const order = !savedOrder ? goalIds : uniq([...savedOrder.order, ...goalIds])
        const topStretchGoal = stretchGoals?.find((goal) => goal.id === order[0]) || localItem
        if (!isLoading) {
            if (topStretchGoal) {
                localItem?.id !== topStretchGoal.id && set(topStretchGoal)
            } else if (localItem) {
                set(null)
            }
        }
        return { order, topStretchGoal, stretchGoals }
    }, [stretchGoalsData, savedOrder, localItem, set, isLoading])

    return {
        stretchGoals,
        order,
        isLoading,
        topStretchGoal,
    }
}

export default useGoals
